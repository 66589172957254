<template>
  <div class="story">
    <full-scroll></full-scroll>
    <div
      class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            "
    >
      <div class="flex flex-pack-center flex-1">
        <img
          src="@/assets/national_emblem.png"
          alt=""
          class="msg-national m_r20"
        />
        <div class="text_align_last">
          <p class="font-size24 m_b10">广阳镇人大代表之家</p>
          <p class="font-size12">
            GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
          </p>
        </div>
      </div>
      <div class="msg-title-mid-box font-size32 font-blod flex-1">
        <img src="@/assets/images/tot-top-title.png" alt="" />
        <div class="msg-title-mid-box-title">
          {{
            $route.query.tid == 19
              ? "广阳故事会"
              : $route.query.tid == 18
                ? "人大故事会"
                : ""
          }}
        </div>
      </div>
      <div class="flex-end flex-1 flex" @click="backHome">
        <div class="my_text_center">
          <img
            src="@/assets/images/backhome-new.png"
            alt=""
            class="msg-backhome m_b10"
          />
          <p>返回首页</p>
        </div>
      </div>
    </div>

    <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
      <div class="notice-box">
        <div class="notice-box-list" id="notice-box-list">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <li
                class="notice-list flex"
                v-for="(item, id) in newAdverList"
                :key="id"
              >
                <div
                  class="m_r20"
                  v-if="
                                        item.cover != '' && item.cover != null
                                    "
                >
                  <img :src="item.cover" alt="" />
                </div>
                <div
                  class="story-video m_r20"
                  v-if="
                                        item.video != '' && item.video != null
                                    "
                >
                  <img src="@/assets/play-icon.png" alt="" />
                  <video :src="item.video + '#t=5.0'"></video>
                </div>
                <div
                  class="
                                        flex-1
                                        m_r20
                                        flex flex-v flex-justify-center
                                    "
                >
                  <div class="notice-list-box color_FF">
                    <p class="font-size24 m_b20">
                      {{ item.title }}
                    </p>
                    <p class="font-size22 color_9FB">
                      {{ item.releaseTime }}
                    </p>
                  </div>
                </div>
                <div
                  class="
                                        check-info
                                        flex
                                        flex-justify-center
                                        flex-pack-center
                                    "
                >
                                    <span
                                      class="font-size18 color_FF"
                                      @click="goToDetail(item)"
                                    >查看详情</span
                                    >
                </div>
              </li>
            </van-list>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            "
    >
      <div class="my_text_center" @click="goBack">
        <img
          src="@/assets/images/return-new.png"
          alt=""
          class="msg-return m_b10"
        />
        <p class="font-size24 color_FF">返回上级</p>
      </div>
      <div class="font-blod my_text_center color_FF"></div>

      <div class="my_text_center color_FF">
        <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
        <p class="font-size14">{{ todayTime }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsList } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
  components: { fullScroll },
  data () {
    return {
      loading: false,
      finished: false,
      totalPage: null,
      todayMtime: "",
      todayTime: "",
      newAdverList: [],
      pageNum: 1,
      pageSize: 6,

      scrollTop: 0
    };
  },
  created () {
    this.getTime();
  },
  activated () {
    document.getElementById("notice-box-list").scrollTop = this.scrollTop; // 设置滚动条位置
  },

  mounted () {
    this.getFetch();
    this.timer = setInterval(() => {
      this.getTime();
    }, 1000);
  },
  methods: {
    getTime () {
      let _this = this;
      let time = "";
      time = dateTimeFormat(new Date());
      _this.todayTime = time.split(" ")[0];
      _this.todayMtime = time.split(" ")[1].slice(0, 5);
    },
    getFetch () {
      getNewsList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        tid: this.$route.query.tid,
        status: 1
      }).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          let data = res.data.list;
          if (data.length == 0) {
            this.finished = true;
          } else {
            if (this.pageNum == res.data.totalPage) {
              this.finished = true;
            } else {
              this.finished = false;
            }

            data.forEach((item) => {
              item.releaseTime = item.releaseTime.split(" ")[0];
            });
            this.totalPage = res.data.totalPage;
            this.newAdverList.push(...data);
          }
        }
      });
    },

    onLoad () {
      if (this.totalPage <= this.pageNum) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = true;
        this.pageNum++;
        this.getFetch();
      }
    },

    goToDetail (data) {
      this.scrollTop =
        document.getElementById("notice-box-list").scrollTop;
      let Data = JSON.stringify(data);
      this.$router.push({
        name: "supervisedetail",
        params: { data: encodeURIComponent(Data), type: 4 }
      });
    },
    //返回首页
    backHome () {
      this.$router.push("/");
    },
    goBack () {
      this.$router.back();
    }
  }
};
</script>
